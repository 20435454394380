import React from 'react';

const LocationMap = ({ data }) => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3265.125136969694!2d-80.6588967987051!3d35.078605970294724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x885424e0cf4128b1%3A0x3e0440129440ee75!2s215%20Rowell%20Dr%2C%20Indian%20Trail%2C%20NC%2028079!5e0!3m2!1sen!2sus!4v1611962568996!5m2!1sen!2sus"
      width="100%"
      height="450"
      frameBorder="0"
      allowFullScreen=""
      aria-hidden="false"
      tabIndex="0"
    />
  );
};
export default LocationMap;
