import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import HomePage from '../components/homepage';
import Layout from '../components/layout';
import { indexMenuLinks } from '../components/_config/menu-links';
import '../fonts/gi-joe/Action_Force.ttf';
import '../fonts/piston-pressure/Piston_Pressure.otf';

const Index = ({ data }) => {
  return (
    <Layout menuLinks={indexMenuLinks}>
      <HomePage data={data} />
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  {
    site {
      siteMetadata {
        author
        keywords
      }
    }

    hero: markdownRemark(fileAbsolutePath: { regex: "/content/sections/hero/" }) {
      frontmatter {
        tagline
        cta_label
        cta_link
      }
      html
    }

    about: markdownRemark(fileAbsolutePath: { regex: "/content/sections/about/" }) {
      frontmatter {
        title
        techs
        about_image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }

    cards: markdownRemark(fileAbsolutePath: { regex: "/content/sections/cards/" }) {
      frontmatter {
        cards {
          label
          icon
          prefix
          description
        }
      }
      html
    }

    trainers: markdownRemark(fileAbsolutePath: { regex: "/content/sections/trainers/" }) {
      frontmatter {
        trainers {
          trainerName
          imageName
          title
          specializations
          description
        }
      }
      html
    }

    featuredProjects: allMarkdownRemark(
      limit: 3
      sort: { order: DESC, fields: frontmatter___trainerName }
      filter: { fileAbsolutePath: { regex: "/content/projects/" }, frontmatter: { featured: { eq: true } } }
    ) {
      nodes {
        frontmatter {
          trainerName
          trainerTitle
          youtubeLink
          repo_link
          techs
          cover_image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        html
      }
    }

    contact: markdownRemark(fileAbsolutePath: { regex: "/content/sections/contact/" }) {
      frontmatter {
        phone
        email
        address
      }
      html
    }
  }
`;
