import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import logo from '../images/tsvlogo.jpeg';
import ButtonLink from './links/button-link';
import ScrollIndicator from './scroll-indicator';
import { mq } from './_shared/media';
import { StyledSection } from './_shared/styled-section';

const StyledHeroSection = styled(StyledSection)`
  min-height: calc(100vh - 2 * var(--header-height));
  position: relative;

  ${mq.gt.sm} {
    min-height: calc(100vh - var(--header-height));
  }
`;
const StyledIntroduction = styled.div`
  color: var(--primary-color);
  font-weight: normal;
`;
const StyledAuthor = styled.h1`
  margin-left: -4px !important;
  font-size: 40px;
  line-height: 1.1;
  margin: 0;
  word-break: break-word;

  ${mq.gt.xs} {
    font-size: 80px;
  }
`;
const StyledTagline = styled.h2`
  font-size: 35px;
  line-height: 1.1;
  margin: 0;
  color: var(--welcome-color);
  position: absolute;
  text-align: center;
  top: 60%;
  left: 0%;
  right: 0%;
  width: 100%;

  ${mq.gt.xs} {
    font-size: 80px;
    top: 20%;
    left: 25%;
    letter-spacing: 5px;
  }
`;
const StyledDescription = styled.div`
  margin-top: 0.5rem;
  width: 100%;
  max-width: 500px;
  color: var(--welcome-color);
  position: absolute;
  top: 70%;
  left: 0%;
  right: 0%;
  text-align: center;
  ${mq.gt.xs} {
    top: 35%;
    left: 50%;
    font-size: 30px;
  }
`;
const StyledHeaderImage = styled.img`
  position: absolute;
  height: 50%;
  left: 5%;
  ${mq.gt.xs} {
    height: 80%;
  }
`;

const Hero = ({ data }) => {
  const { tagline, description, ctaLink, ctaLabel } = data;

  return (
    <div style={{ backgroundColor: 'black' }}>
      <div>
        <StyledHeaderImage src={logo} />
      </div>
      <StyledHeroSection>
        <StyledTagline>{tagline}</StyledTagline>
        <StyledDescription dangerouslySetInnerHTML={{ __html: description }} />
        <ButtonLink label={ctaLabel} link={ctaLink} />
        <ScrollIndicator />
      </StyledHeroSection>
    </div>
  );
};

Hero.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Hero;
