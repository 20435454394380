import React from 'react';
import { useEffect } from 'react';
import About from './about';
import CardGrid from './card-grid';
import Contact from './contact';
import FeaturedProjects from './featured-projects';
import Hero from './hero';
import SEO from './seo';
import { StyledH1 } from './_shared/styled-headings';
import { StyledSection } from './_shared/styled-section';

const HomePage = ({ data }) => {
  const [value, setValue] = React.useState('none');
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const heroData = {
    tagline: data.hero.frontmatter.tagline,
    description: data.hero.html,
    ctaLabel: data.hero.frontmatter.cta_label,
    ctaLink: data.hero.frontmatter.cta_link,
  };
  return (
    <div>
      <SEO title="The Strength Vault" />
      <Hero data={heroData} />
      <About data={data.about} />
      <CardGrid cards={data.cards.frontmatter.cards} description={data.cards.html} title="What We Offer" id="offer" />
      <FeaturedProjects featured={data.featuredProjects.nodes} />
      <StyledSection id="booking">
        <StyledH1>Booking</StyledH1>
        <div
          style={{
            position: 'relative',
            border: 'black 1px solid',
            backgroundColor: 'white',
            width: '100%',
            height: '100%',
          }}
        >
          <iframe
            src="https://www.schedulicity.com/scheduling/TSVQY6S/services?embed=true"
            allowtransparency="true"
            frameBorder="0"
            style={{
              border: 'none',
              width: '100%',
              height: '100%',
              minHeight: '800px',
              padding: 0,
              margin: 0,
              zIndex: 1,
            }}
          />
        </div>
      </StyledSection>
      <Contact data={data.contact} />
    </div>
  );
};
export default HomePage;
